<template>
  <sign-page
    title-text="维修管理"
    :request="request"
    :column-list="columnList"
    table-size="small"
    ref="repairpage"
    :onFormChange="onFormChange"
    :table-actions="tableActions"
    @tableAction="tableAction"
    :form-parms-add="formParmsAdd"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal title="维修申请详细" v-model="modal" width="1000px" v-if="modal">
      <detail v-if="chooseData.assetsRepairApplyId" :statusMap="statusMap" :status-config="statusConfig" :id="chooseData.assetsRepairApplyId"></detail>
      <div style="text-align: center;line-height: 100px;font-size: 16px;" v-else>未走维修申请流程</div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  assetsRepairRequest as request,
  statusManageRequest
} from '@/api'

import TextCell from '@/components/base/TextCell'

import detail from './apply/detail'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    detail
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'over',
        label: '完成维修',
        show: (data) => {
          return data.status === 'doing'
        }
      },
      {
        key: 'apply',
        label: '相关申请'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '资产编码',
          key: 'assetsId',
          selectDatas: this.$store.getters.assetsList,
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '维修厂家',
          key: 'repairCmp'
        },
        {
          type: 'datePicker',
          label: '维修开始日期',
          key: 'startTime',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '维修状态',
          key: 'status',
          selectDatas: [{key: 'doing', label: '维修中'}, {key: 'over', label: '完成维修'}],
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '维修结束日期',
          key: 'endTime',
          show: (formData) => {
            return formData.status === 'over'
          },
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '维修明细',
          key: 'detail'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '维修记录编号',
          field: 'code',
          sort: true
        },
        {
          title: '资产编号',
          field: 'assetsCode',
          sort: true
        },
        {
          title: '资产名称',
          field: 'assetsName',
          sort: true
        },
        {
          title: '规格型号',
          field: 'assetsGgxh',
          sort: true
        },
        {
          title: '价值',
          dataType: Number,
          field: 'assetsJz',
          sort: true
        },
        {
          title: '使用机构',
          field: 'assetsUseOrgName',
          sort: true
        },
        {
          title: '维修厂家',
          field: 'repairCmp',
          sort: true
        },
        {
          title: '维修开始日期',
          field: 'startTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 10) : '-')
          }
        },
        {
          title: '维修结束日期',
          field: 'endTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '-')
          }
        },
        {
          title: '维修明细',
          field: 'detail',
          sort: true,
          render: (h, row) => {
            return h(TextCell, {
              props: {
                value: row.detail
              }
            })
          }
        },
        {
          title: '状态',
          field: 'statusText',
          sort: true
        }]
        return data
      }
    }
  },
  created () {
    if (this.$authFunsProxy.edit || this.$authFunsProxy.add) {
      this.$store.dispatch('loadAssetsList')
    }
    if (this.$authFunsProxy.apply) {
      this.loadConfig()
    }
  },
  methods: {
    onFormChange (key, value) {
      if (key === 'status' && value === 'doing') {
        return {
          endTime: null
        }
      }
    },
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'assetsRepairApply'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      this.statusConfig = {
        norm: c.statusSwitchList.filter(v => v.isHost && (!v.tag || v.tag === 'norm')),
        mini: c.statusSwitchList.filter(v => v.isHost && (!v.tag || v.tag === 'mini')),
      }
    },
    async tableAction (parm) {
      this.chooseData = parm.data
      if (parm.action === 'apply') {
        this.modal = true
        return
      }
      let data = parm.data
      await request.update(data.id, {
        status: 'over',
        endTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd'})
      })
      this.$refs.repairpage.loadData()
    },
    async loadData () {
      let data = await request.get()
      data.forEach(v => v.statusText = v.status === 'doing' ? '维修中' : '完成维修')
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      chooseData: null,
      statusMap: {},
      statusConfig: {},
      pageVm: null,
      modal: false
    }
  }
}
</script>

<style lang="less" scoped>
.img-c {
  height: 60vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .no-img {
    font-size: 1.4rem;
    color: #999;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.file-d-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-d-footer-wx {
  display: none;
  justify-content: center;
  align-items: center;
}
</style>